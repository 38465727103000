import React from 'react';
import Layout from '../../components/App/Layout';
import Navbar from '../../components/App/Navbar';
import PageBanner from '../../components/Common/PageBanner';
import Footer from '../../components/App/Footer';
import StartProject from '../../components/Common/StartProject';
import CaseStudiesSidebar from '../../components/CaseStudies/CaseStudiesSidebar';
import project2 from '../../assets/images/projects/project2.jpg';

const ProjectDetailContent = () => {
  return (
    <section className="case-studies-details-area ptb-100">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-12">
            <div className="case-studies-details-image">
              <img
                src="https://assets-global.website-files.com/651a7ef2da4196fc6935793f/65858bdb5cd373203de5351f_Case%20study%20cover%20V2%20(13).png"
                alt="about"
              />
            </div>
            <div className="case-studies-details-desc">
              <span className="sub-title">SaaS Application Development</span>
              <h3>A new kind of stock content platform</h3>
              <p>
                Cinestock is a Shutterstock equivalent for film. Driven by the
                recognition that existing stock footage and images online were
                not easily accessible, often expensive, and lacked accurate
                representation of the Middle East, North Africa, and the Indian
                subcontinent, the company envisioned a localized platform.
                Cinestock sought to empower local photographers and content
                creators to upload their work, creating a diverse and accessible
                library of stock videos for users.
              </p>
              <p>
                The primary challenge lay in the intricacies of video
                processing. This encompassed handling high-detail, large video
                files directly from cameras, transcoding and encoding these
                files into accessible formats for previews, watermarking to
                protect against unauthorized use, and facilitating multi-format
                downloads. Building a robust microservices-based backend
                architecture was imperative, requiring powerful servers for
                video processing and a responsive web server for the user
                interface.
              </p>

              <h3>1. Backend Architecture:</h3>
              <p>
                Our approach involved designing a robust backend architecture,
                prioritizing powerful servers for video encoding and transcoding
                tasks. Microservices were strategically implemented to
                efficiently handle the complex processes associated with video
                processing.
              </p>

              <h3>2. User Interface:</h3>
              <p>
                We developed a user-friendly web application comprising both a
                portal for end-users to browse and download footage, including
                watermarked samples, and a contributor portal. Content creators
                could sign up, undergo approval based on the quality of their
                work, upload videos, and manage payment details, allowing for
                seamless contributions to the platform.
              </p>

              <h3>3. Payment Gateway:</h3>
              <p>
                Implementing a secure and efficient payment mechanism was
                crucial. We built a comprehensive system for payment collection,
                disbursement in the marketplace, and notifications. Contributors
                received alerts on video processing completion, purchases, and
                fund availability for withdrawal, ensuring transparency and
                timely communication.
              </p>
            </div>
          </div>

          <div className="col-lg-4 col-md-12">
            <CaseStudiesSidebar
              client="Cinestock"
              location="Dubai, UAE"
              tech="Node, React, MongoDB, AWS, FFMPEG"
              completionDate="21 March 2023"
              website="https://cinestock.io"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

const ProjectDetailPage = () => {
  return (
    <Layout>
      <Navbar />
      <PageBanner
        pageTitle="Cinestock"
        homePageText="Home"
        homePageUrl="/"
        activePageText="Cinestock"
      />
      <ProjectDetailContent />
      <StartProject />
      <Footer />
    </Layout>
  );
};

export default ProjectDetailPage;
