import React from 'react';

const CaseStudiesSidebar = ({
  client,
  location,
  tech,
  completionDate,
  website,
}) => {
  return (
    <div className="case-studies-sidebar-sticky">
      <div className="case-studies-details-info">
        <ul>
          <li>
            <div className="icon">
              <i className="bx bx-user-pin"></i>
            </div>
            <span>Client:</span>
            {client}
          </li>
          <li>
            <div className="icon">
              <i className="bx bx-map"></i>
            </div>
            <span>Location:</span>
            {location}
          </li>
          <li>
            <div className="icon">
              <i className="bx bx-purchase-tag"></i>
            </div>
            <span>Technologies:</span>
            {tech}
          </li>
          <li>
            <div className="icon">
              <i className="bx bx-check"></i>
            </div>
            <span>Completed:</span>
            {completionDate}
          </li>
          <li>
            <div className="icon">
              <i className="bx bx-globe"></i>
            </div>
            <span>Website:</span>
            <a href={website} target="_blank" rel="noreferrer">
              {website}
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default CaseStudiesSidebar;
